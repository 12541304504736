import Vue from 'vue'
import App from './App.vue'


Vue.config.productionTip = false

import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
import './assets/css/index.less';
Vue.use(ViewUI);
import {Message} from 'view-design'
Vue.prototype.$Message = Message

import store from './store/index'

// 移动端vant 2 ui框架
import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);

//工具函数
import Tools from './tool/index'
Vue.prototype.$Tools = Tools;

//接口们
import { Api,bsApi,coingnApi,hkshareApi,foxexApi } from './network/index'
// 请求方法 全局变量
Vue.prototype.$Api = Api;
Vue.prototype.$bsApi = bsApi;
Vue.prototype.$coingnApi = coingnApi;
Vue.prototype.$hkshareApi = hkshareApi;
Vue.prototype.$foxexApi = foxexApi;
// const serve = 'http://192.168.101.10/wooh'
// const serve = 'http://39.107.247.125/wooh'
// Vue.prototype.$serve = serve;
// 上传图片路径
// Vue.prototype.$imageUrl = serve + "/LayUiUploadController/uploadFtpFile.json";

import router from './router'
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
