/*
 * @Author: toString
 * @Date: 2023-09-19 12:19:33
 * @LastEditTime: 2023-11-09 19:36:42
 * @LastEditors: toString
 * @Description:
 * @FilePath: \market-share\src\utils\getUrlKey.js
 */
export function getUrlKey(name) {
  return (
    decodeURIComponent(
      (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
        location.href
      ) || [, ""])[1].replace(/\+/g, "%20")
    ) || null
  );
}

export function getApiUrl() {
  // let host = window.location.host.split('.');
  // host[0] = 'api';
  // return "http://" + host.join('.') + '/uc';
  const host = window.location.host;
  // return "http://" + host.replace("share", "api") + "/uc";
  return "http://api.comcn.online/uc";
}
