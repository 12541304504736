/**
 * Vue axios 二次封装 请求数据接口
 */
import Vue from 'vue';
import axios from 'axios';
import store from '@/store';
import router from '../router'
import ViewUI from 'view-design';
import main from '../main.js';
import qs from 'qs';
import { getApiUrl } from '../utils/getUrlKey'

//获取url中的token
const instance = axios.create({
	// baseURL: '/api', // `baseURL`将被添加到`url`前面，除非`url`是绝对的。
	// baseURL: 'http://api.fmcangping.com/nftApi',	//11
	// baseURL: 'http://nft.kplepool.com/nftApi', // 老金
	// baseURL: 'http://api.osstar.xyz/uc', // OS
	// baseURL: 'http://184.168.125.50:8096/uc', // Rays
	// baseURL: 'https://api.atokenn.com/uc', // AT
	// baseURL: 'http://192.168.0.99/uc', 
	baseURL: getApiUrl(), 
	
	// baseURL: 'http://api.xingyuanshuzi.cn/nftApi', // 星源ART
	timeout: 50000, // `timeout`指定请求超时之前的毫秒数。
	transformRequest: [function(params = {}){//`transformRequest`允许在请求数据发送到服务器之前对其进行更改
		const data = {...params}
		// return data
		return qs.stringify(data, {
			// indices brackets repeat comma
			arrayFormat: 'brackets' //可以通过设置 arrayFormat 选项指定数组输出格式：
		})
	}]
});
// instance.defaults.headers['Content-Type'] = 'multipart/form-data';
// instance.defaults.headers['Content-Type'] = 'application/json';
// instance.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded';
instance.defaults.withCredentials = false; //`withCredentials`指示是否跨站点访问控制请求
//添加请求拦截器
instance.interceptors.request.use((config) => {
	console.log(config)
	// if (sessionStorage.getItem("adminId") && sessionStorage.getItem("token")) {
	// 	store.dispatch('loading/startLoading', {
	// 		loading: config.data && config.data.loading
	// 	});
	// }else{
	// 	store.dispatch('loading/startLoading', {
	// 		loading: false
	// 	});
	// }
	return config;
}, (error) => {
	// 请求错误时做些事(接口错误、超时等)
	// Tip: 4
	// 关闭loadding
	// store.dispatch('loading/finishLoading');
	console.log('request:', error)

	//  1.判断请求超时
	if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
		console.log('根据你设置的timeout/真的请求超时 判断请求现在超时了，你可以在这里加入超时的处理方案')
		return service.request(originalRequest); //例如再重复请求一次
	}

	//  2.需要重定向到错误页面
	const errorInfo = error.response
	console.log(errorInfo)
	if (errorInfo) {
		// error =errorInfo.data//页面那边catch的时候就能拿到详细的错误信息,看最下边的Promise.reject
		const errorStatus = errorInfo.status; // 404 403 500 ... 等
		router.push({
			path: `/error/${errorStatus}`
		})
	}
	return Promise.reject(error) //在调用的那边可以拿到(catch)你想返回的错误信息
});

//添加响应拦截器！！！！注意data！！！
instance.interceptors.response.use((response) => {
	if(response.data.code == 0){
		// console.log(response.data.data)
		// ViewUI.Message.success(response.data.data)
		return response.data
	}else if(response.data.code == -2){
		console.log(222+"登录超时")
		// 返回登陆页面
		// router.push({
		// 	path: `/` 
		// })
		return response.data
	}else{
		return response.data
		// ViewUI.Message.error(response.data.msg)
	}

}, (err) => {
	if (err && err.response) {
		switch (err.response.status) {
			case 400:
				err.message = '请求错误'
				break;

			case 401:
				err.message = '未授权，请登录'
				break;

			case 403:
				err.message = '拒绝访问'
				break;

			case 404:
				err.message = `请求地址出错: ${err.response.config.url}`
				break;

			case 408:
				err.message = '请求超时'
				break;

			case 500:
				err.message = '服务器内部错误'
				break;

			case 501:
				err.message = '服务未实现'
				break;

			case 502:
				err.message = '网关错误'
				break;

			case 503:
				err.message = '服务不可用'
				break;

			case 504:
				err.message = '网关超时'
				break;

			case 505:
				err.message = 'HTTP版本不受支持'
				break;

			default:
				break;
		}
	}
	console.log(err)
	message.error(`ERROR: ${err}`);
	return Promise.reject(err) // 返回接口返回的错误信息
});

export default instance;
