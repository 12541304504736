<!--
 * @Author: toString
 * @Date: 2023-12-20 16:36:44
 * @LastEditTime: 2023-12-20 18:28:46
 * @LastEditors: toString
 * @Description: 下载页
 * @FilePath: \market-share\src\views\download\CoinProDown.vue
-->
<template>
  <div class="down">
    <img
      class="apk-img-info"
      src="@/assets/download/coin-pro-down.png"
      style="width: 100%;"
    />
    <div class="operation">
      <div class="apk-down-btn" @click="download">
        <img class="apk-img" src="@/assets/download/apk.png" />
        <div class="apk-text">APK</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    download(){
      window.location = sessionStorage.getItem('downUrl')
    }
  },
};
</script>
<style scoped>
.down {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.operation {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 20px;
  display: flex;
  justify-content: center;
}
.apk-down-btn {
  width: 182px;
  height: 46px;
  background: #00e183;
  border-radius: 60px 60px 60px 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.apk-img {
  width: 39px;
  height: 22.5px;
}
.apk-text {
  width: 34px;
  height: 26px;
  font-size: 18px;
  color: #ffffff;
}
</style>
