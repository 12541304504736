/*
 * @Author: toString
 * @Date: 2023-12-05 15:45:37
 * @LastEditTime: 2023-12-20 18:29:04
 * @LastEditors: toString
 * @Description: 
 * @FilePath: \market-share\src\router\index.js
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
//解决重复点击报错 
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
//导入index页路由懒加载
// import Layout from '@/views/Index.vue'


const HK_share = () => import( /* webpackChunkName: "add" */ '@/views/sharePage/HK_share')
const CoinProDown = () => import( /* webpackChunkName: "add" */ '@/views/download/CoinProDown')
  const routes = [
  {
    path: '/hk_share',
    name: '邀请注册',
    component: HK_share,
    meta : {
      requireAuth: true      //不需要登录进入
    },
  },
  {
    path: '/CoinProDown',
    name: 'APP下载',
    component: CoinProDown,
    meta : {
      requireAuth: true      //不需要登录进入
    }
  }
]


const router = new VueRouter({
  routes
})

export default router
