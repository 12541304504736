import Vue from 'vue'

function extractByKey(arr,key,resultType='String',separator=',') {
  let resultArr = [];
  arr.map(item => {
      if(item[key]) resultArr.push(item[key]);
  })
  if(resultType==='Array') return resultArr;
  else if(resultType==='String')return resultArr.join(separator)
  else throw new Error("resultType无效");
}

//时间戳转换
function timeFormat(timestamp){
  var time = new Date(timestamp*1000);
  var year = time.getFullYear();
  var month = time.getMonth()+1;
  var date = time.getDate();
  return year + '/' + addZero(month) + '/' + addZero(date)
}
function addZero(num) { return num < 10 ? '0' + num : num }

//防止多次点击按钮
const preventReClick = Vue.directive('preventReClick', {
  inserted: function (el, binding) {
      el.addEventListener('click', () => {
          if (!el.disabled) {
              el.disabled = true
              setTimeout(() => {
                  el.disabled = false
              }, binding.value || 1500)
          }
      })
  }
});

export default({ extractByKey, timeFormat, preventReClick })