import fetch from './coingnfetch';
export default {
	/*****************两个web页************************/
	// 发送验证码
	sendCode(data){
		return fetch.put(`/sendCode/${data.phone}`);
	},
	sendAccountCode(data){
		return fetch.post(`/reg/email/code`,{
			...data
		});
	},
	// 是否已注册
	userinfoByPhone(data){
		return fetch.get(`/userinfoByPhone/${data.phone}`);
	},
	// web页注册
	regWithPass(data){
		return fetch.post("/register/email", {
			...data
		});
	},
	// web页注册
	appGetPlate(){
		return fetch.post("/sysPlateConfig/appGetPlate");
	},
	// 应用信息
	getVersion(data){
		return fetch.post("/sysAppVersion/list", {
			...data
		});
	},

	
	/*****************OS专用************************/
	// 邮箱验证码
	setEmailcode(data){
		return fetch.post(`/reg/email/code?email=${data.email}`);
	},
	// web页注册
	registerWithEmail(data){
		return fetch.post("/register/email", {
			...data
		});
	},
}





